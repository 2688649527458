/* Google maps */
.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}
.pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
}
.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    animation-delay: 1.1s;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }

    80% {
        transform: translateY(-10px) rotate(-45deg);
    }

    100% {
        transform: translateY(0) rotate(-45deg);
    }
}

/* UK Constituency Map */
.container {
    width: inherit;
}

#electionContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: 1px solid #EEEEEE;
    position: relative;
    background-color: #FFFFFF;
}

#electionMapContainer {
    width: 54%;
    background-color: #FFFFFF;
    border-left: 1px solid #EEEEEE;
    order: 2;
}

#electionMap {
    overflow: hidden;
    position: relative;
    background-color: #FFFFFF;
}

.subunits {
  fill: none;
  stroke: #FFFFFF;
  stroke-linejoin: round;
  stroke-width: 0.1;
}

.ward:hover {
    opacity: 0.8;
    stroke: #000;
    stroke-width: 0.3;
    stroke-linejoin: round;
}

.f0 {
    fill: #222222
}

.f1 {
    fill: #DA1502
}

.f2 {
    fill: #0382AB
}

.f3 {
    fill: #F0DE4C
}

.f4 {
    fill: #3C862D
}

.f5 {
    fill: #FDB218
}

.f6 {
    fill: #df1c41
}

.f7 {
    fill: #7AB630
}

.f8 {
    fill: #999999;
}

.f9 {
    fill: #722889
}

.f10 {
    fill: #FF9900
}

.f11 {
    fill: gray;
}